import React from 'react'
import tw from 'twin.macro'

const Section = tw.section`relative py-20 mt-12`
const SectionContainer = tw.div`container max-w-6xl mx-auto `
const SectionTitle = tw.h2`text-2xl sm:text-3xl md:text-4xl font-bold text-center text-gray-900 mb-14 tracking-tight`

// const BackgroundPanel = tw.div`hidden top-5 left-0 right-0 flex-none md:block absolute w-full rounded-lg mx-auto bg-gradient-to-r from-green-400 to-emerald-500 h-80 -z-10 bg-opacity-25`;

const GridContainer = tw.div`grid grid-cols-4 gap-8 mt-10 sm:grid-cols-8 lg:grid-cols-12 sm:px-8 xl:px-0`

const Card = tw.div`relative flex flex-col items-center justify-start col-span-4 px-8 py-12 space-y-4 overflow-hidden bg-gray-100 sm:bg-white sm:border`

// const Card = tw.div`relative flex flex-col items-center justify-start col-span-4 px-8 py-12 space-y-4 overflow-hidden bg-gray-100 sm:bg-white sm:rounded-xl sm:shadow-lg`;

const IconContainer = tw.div`p-3 text-white bg-blue-500 rounded-full`

const CardTitle = tw.h4`text-2xl font-bold text-gray-700`
const CardSubtitle = tw.p`text-base md:text-lg text-center text-gray-500`

function FeaturesSection() {
  return (
    <Section>
      {/*<BackgroundPanel />*/}
      <SectionContainer>
        <SectionTitle>
          Why we are
          <span className=""> the best?</span>
        </SectionTitle>
        <GridContainer>
          <Card>
            <IconContainer>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8 "
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                <path d="M5 8v-3a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5"></path>
                <circle cx="6" cy="14" r="3"></circle>
                <path d="M4.5 17l-1.5 5l3 -1.5l3 1.5l-1.5 -5"></path>
              </svg>
            </IconContainer>
            <CardTitle>Quality</CardTitle>
            <CardSubtitle>
              Sinaran Rempah Asli has been delivering quality spices and herbs
              for more than 40 years, with rigorous standards for sourcing &
              manufacturing to provide the freshest, purest premium product
              available.
              {/*It has been about 50 years ago since we served our first-ever
              spice range. Since that day, we have kept the quality of the
              spices ever increasing. We believe in producing spices and herbs
              with uncompromised quality. We have set rigorous standards for
              collecting herbs and manufacturing spices to provide you with pure
              and fresh products. The best quality premium products have made us
              stand out among our competitors. */}
            </CardSubtitle>
          </Card>

          <Card>
            <IconContainer>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8 "
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M18 8a3 3 0 0 1 0 6"></path>
                <path d="M10 8v11a1 1 0 0 1 -1 1h-1a1 1 0 0 1 -1 -1v-5"></path>
                <path d="M12 8h0l4.524 -3.77a0.9 .9 0 0 1 1.476 .692v12.156a0.9 .9 0 0 1 -1.476 .692l-4.524 -3.77h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h8"></path>
              </svg>
            </IconContainer>
            <CardTitle>Expertise</CardTitle>
            <CardSubtitle>
              We have decades of experience and extensive knowledge of the
              global spice industry and have developed long-term relationships
              with global sourcing partners, enabling us to serve as trusted
              advisors to our customers.
              {/*We believe that honesty is the best policy. All our dealings are
              based on integrity and honesty. There is no use of any impurity
              when it comes to the eatables. We have always done everything with
              integrity, whether it&apos;s sourcing or manufacturing, or dealing
              with our partners. Many kitchen experts trust us with their food,
              and we have earned our place in my hearts with our honest and
              integrated dealings.*/}
            </CardSubtitle>
          </Card>

          <Card>
            <IconContainer>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8 "
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"></polyline>
                <line x1="12" y1="12" x2="20" y2="7.5"></line>
                <line x1="12" y1="12" x2="12" y2="21"></line>
                <line x1="12" y1="12" x2="4" y2="7.5"></line>
                <line x1="16" y1="5.25" x2="8" y2="9.75"></line>
              </svg>
            </IconContainer>
            <CardTitle>Integrity</CardTitle>
            <CardSubtitle>
              Everything we do, from sourcing to domestic processing to working
              with our partners, is rooted in honesty and integrity.
              {/*What makes us stand out in the market is we have learned from our
              experiences. We have expertise in our field and work. The decades
              of experience and hard work have made us where we stand today. We
              make sure that we have good relations with the global spic
              industry and our standards match theirs. We have gained detailed
              knowledge about every minor detail of spice manufacturing. It
              enables us to serve you and gain the trust of our customers. Our
              experts work wholeheartedly to get the best and purest spices and
              herbs for our customers.*/}
            </CardSubtitle>
          </Card>

          {/* <div className="flex flex-col items-center justify-start col-span-4 px-8 py-12 space-y-4 bg-gray-100 sm:rounded-xl">
            <IconContainer>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8 "
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M8 9l3 3l-3 3"></path>
                <line x1="13" y1="15" x2="16" y2="15"></line>
                <rect x="3" y="4" width="18" height="16" rx="2"></rect>
              </svg>
            </div>
            <CardTitle>
              Natural flavours
            </CardTitle>
            <CardSubtitle>
              Vibrant, highly concentrated flavours and strictly NO fillers,
              preservatives, oil OR refined sugar!
            </CardSubtitle>
          </div>

          <div className="flex flex-col items-center justify-start col-span-4 px-8 py-12 space-y-4 bg-gray-100 sm:rounded-xl">
            <IconContainer>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8 "
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="9.5" y1="11" x2="9.51" y2="11"></line>
                <line x1="14.5" y1="11" x2="14.51" y2="11"></line>
                <path d="M9.5 15a3.5 3.5 0 0 0 5 0"></path>
                <path d="M7 5h1v-2h8v2h1a3 3 0 0 1 3 3v9a3 3 0 0 1 -3 3v1h-10v-1a3 3 0 0 1 -3 -3v-9a3 3 0 0 1 3 -3"></path>
              </svg>
            </div>
            <CardTitle>
              Open & Transparent
            </CardTitle>
            <CardSubtitle>
              We don’t use made-up marketing terms. We believe in cutting
              through the noise to simply give you the best quality.
            </CardSubtitle>
          </div>

          <div className="flex flex-col items-center justify-start col-span-4 px-8 py-12 space-y-4 bg-gray-100 sm:rounded-xl">
            <IconContainer>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8 "
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="15" y1="5" x2="15" y2="7"></line>
                <line x1="15" y1="11" x2="15" y2="13"></line>
                <line x1="15" y1="17" x2="15" y2="19"></line>
                <path d="M5 5h14a2 2 0 0 1 2 2v3a2 2 0 0 0 0 4v3a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-3a2 2 0 0 0 0 -4v-3a2 2 0 0 1 2 -2"></path>
              </svg>
            </div>
            <CardTitle>
              HALAL Certified
            </CardTitle>
            <CardSubtitle>
              We are HALAL certified from JAKIM and we are able to supply our
              spices and products to the market in Malaysia.
            </CardSubtitle>
          </div> */}
        </GridContainer>
      </SectionContainer>
    </Section>
  )
}

export default FeaturesSection
