import React from 'react'
import mail from '../images/icons8-mail.svg'
import whatsapp from '../images/icons8-whatsapp.svg'
import facebook from '../images/icons8-facebook.svg'

const SideSocialMedia = () => {
  return (
    <div className="fixed right-0 top-1/4 z-50 pl-1 pr-1 py-3 bg-white flex flex-col rounded-l shadow-sm divide-y">
      <a
        className="py-2"
        href="mailto:sinaranrempahasli8888@gmail.com"
        title="Email"
        rel="noreferrer"
        target="_blank"
      >
        <img
          alt="Email"
          className="block mx-auto w-1/2"
          src={mail}
          href=""
        />
      </a>
      <a
        className="py-2"
        href="https://wa.me/0167783867"
        title="Whatsapp"
        rel="noreferrer"
        target="_blank"
      >
        <img
          alt="Whatsapp"
          className="block mx-auto w-1/2"
          src={whatsapp}
        />
      </a>
      <a
        className="py-2"
        href="https://facebook.com/SinaranRempahAsli/"
        title="Share on Facebook"
        rel="noreferrer"
        target="_blank"
      >
        <img
          alt="facebook"
          className="block mx-auto w-1/2"
          src={facebook}
        />
      </a>
    </div>
  )
}

export default SideSocialMedia
