import tw, { styled } from 'twin.macro'

import BackgroundImage from 'gatsby-background-image'
import React from 'react'

const styleMap = {
  halfOpacity: tw`bg-opacity-50`,
  thirdQuarterOpacity: tw`bg-opacity-25`,
  halfWidth: tw`w-full lg:w-1/2 lg:pr-12`,
  fullWidth: tw`w-full min-h-1/2-screen`,
  'min-h-3/4-screen': tw`min-h-3/4-screen`,
  'min-h-1/2-screen bg-center': tw`min-h-1/2-screen bg-center`,
}

const getStyleName = ({ name }) => styleMap[name]

const Container = tw.div`relative`
const BlackOverlay = styled.div(() => [
  tw`absolute inset-0 w-full h-full bg-black`,
  getStyleName,
])

const TwoColumn = styled.div(() => [
  tw`flex flex-col max-w-screen-xl mx-auto md:justify-center lg:flex-row md:items-center p-6 lg:p-0`,
  getStyleName,
])

const LeftColumn = styled.div(() => [
  tw`relative lg:w-6/12 flex-shrink-0 text-center lg:text-left flex flex-col justify-center items-center`,
  getStyleName,
])

const CustomBgHeroTextOnly = ({
  backgroundImageUrl,
  customClassName,
  overlayClassName,
  textContainerWidth,
  headerText,
  button,
}) => {
  return (
    <BackgroundImage
      Tag="section"
      fluid={backgroundImageUrl}
      className="bg-center"
    >
      <Container className={`${customClassName}`}>
        <BlackOverlay name={`${overlayClassName}`} />
        <TwoColumn name={`${customClassName}`}>
          <LeftColumn name={`${textContainerWidth}`}>
            {headerText && headerText}
            {button && button}
          </LeftColumn>
        </TwoColumn>
      </Container>
    </BackgroundImage>
  )
}

export default CustomBgHeroTextOnly
