import { StaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'
import React from 'react'
import tw from 'twin.macro'

const RoundedContainer = tw.p`inline-block px-3 py-2 mb-4 text-xs font-bold tracking-wider text-green-700 uppercase bg-white`

const StatsContainer = tw.div`px-4 py-12 mx-auto sm:max-w-xl md:max-w-full lg:w-full md:px-24 lg:px-0 lg:pt-20 lg:pb-0 overflow-hidden`

const TwoColumn = tw.div`flex flex-col max-w-screen-lg lg:max-w-full overflow-hidden bg-gray-900 rounded-lg lg:rounded-none shadow-xl lg:flex-row sm:mx-auto`

const ColumnWithText = tw.div`flex flex-col justify-center p-8 bg-gray-900 lg:p-16 lg:pl-16 lg:w-1/2`
const ColumnWithImage = tw.div`relative lg:w-1/2`

const HeaderText = tw.h4`mb-3 text-2xl font-bold leading-none sm:text-3xl text-white`
const GridContainer = tw.div`grid grid-cols-1  gap-4`

const Grid = tw.div`w-3/4 text-white mt-2`
const GridHeaderText = tw.h3`text-green-500 text-3xl font-bold`

export default function StatsSection() {
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          file(relativePath: { eq: "photo-1504632236107-4ae29a44f388.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 953, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        //data-sal="slide-up" data-sal-easing="ease"
        <StatsContainer>
          <TwoColumn>
            <ColumnWithText>
              <div>
                <RoundedContainer>
                  {/* Our Track Record */}
                  Our Milestone
                </RoundedContainer>
              </div>
              <HeaderText>
                When it comes to{' '}
                <span className="text-green-500">
                  milestones that we have achieved
                </span>
                , we let the numbers speak in their favor.
                {/* We&apos;ll let{' '}
                <span className="text-green-500">our numbers </span>
                <br />
                talk for themselves */}
              </HeaderText>
              <GridContainer>
                <Grid>
                  <GridHeaderText>50</GridHeaderText>
                  We have worked very hard for over <strong>50 years</strong> to
                  offer our services.
                </Grid>
                <Grid>
                  <GridHeaderText>1500+</GridHeaderText>
                  Our spices are available at more than{' '}
                  <strong> 1500+ locations</strong>.
                </Grid>
                <Grid>
                  <GridHeaderText>30+</GridHeaderText>
                  We now have a complete range of herbs and spices with over{' '}
                  <strong>30 different spices and 100+ products </strong> to
                  satisfy your taste buds with the best.
                </Grid>
                <Grid>
                  <GridHeaderText>60+</GridHeaderText>
                  With our utmost efforts, hard work, and high-quality spices
                  and herbs, we have made so many happy customers and more than
                  <strong> 60 happy clients</strong>.
                </Grid>
              </GridContainer>
            </ColumnWithText>
            <ColumnWithImage>
              <Img
                fluid={data.file.childImageSharp.fluid}
                className={'object-cover w-full lg:absolute h-80 lg:h-full'}
                alt="Spices"
              />
              {/* <img
            src="https://images.unsplash.com/photo-1504632236107-4ae29a44f388?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
            alt="Persons talking in a work setting."
            className="object-cover w-full lg:absolute h-80 lg:h-full"
          /> */}
              <svg
                className="absolute top-0 left-0 hidden h-full text-gray-900 lg:inline-block transform -scale-1 -ml-1"
                viewBox="0 0 20 104"
                fill="currentColor"
              >
                <polygon points="17.3036738 5.68434189e-14 20 5.68434189e-14 20 104 0.824555778 104"></polygon>
              </svg>
            </ColumnWithImage>
          </TwoColumn>
        </StatsContainer>
      )}
    />

    // <div className="relative bg-white min-w-screen">
    //   <div className="relative top-0 left-0 w-full h-64 lg:absolute lg:w-1/2 lg:h-full">
    //     <img
    //       src="https://images.unsplash.com/photo-1509359149003-657ef23eaf04?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80"
    //       alt="Persons sitting around a wooden table coding"
    //       className="inset-0 object-cover w-full h-full lg:absolute"
    //     />
    //   </div>
    //   <div className="flex items-center justify-center h-full max-w-6xl pt-0 pb-20 mx-auto lg:pt-24">
    //     <div className="hidden w-1/2 lg:block"></div>
    //     <div className="relative flex flex-col items-start justify-center w-full h-full pl-12 pr-10 lg:w-1/2 lg:pl-16 lg:pr-0">
    //       <p className="mt-12 lg:mt-0 text-sm font-bold tracking-wide text-indigo-500 uppercase">
    //         About us
    //       </p>
    //       <h2 className="mt-5 text-4xl font-bold leading-tight text-gray-900">
    //         Our story began since <br />
    //         1971
    //       </h2>
    //       <p className="mt-5 text-base text-gray-600">
    //         Are you ready for some badass features? If so, we&apos;ve got you
    //         covered. From Cotton Candy Flavored Donuts to Fully Featured Eggs
    //         and Bacon. Whatever the fuck that means.
    //       </p>
    //       <a
    //         href="#_"
    //         className="flex items-center inline-block mt-8 font-medium text-indigo-500 underline"
    //       >
    //         <span>Learn more about us </span>
    //         <svg
    //           className="w-4 h-4 mt-1 ml-1 transform -rotate-45"
    //           fill="none"
    //           stroke="currentColor"
    //           viewBox="0 0 24 24"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth="2"
    //             d="M14 5l7 7m0 0l-7 7m7-7H3"
    //           ></path>
    //         </svg>
    //       </a>
    //     </div>
    //   </div>
    // </div>
  )
}
