import * as constants from '../consts/index'

import { graphql, Link } from 'gatsby'

import Img from 'gatsby-image'
import AboutUsSection from '../components/AboutUsSection'
// import BestSellerSection from '../components/BestSellerSection'
import CustomBgHero from '../components/CustomBgHero'
import CustomBgHero2 from '../components/CustomBgHeroTextOnly'
import FeaturesSection from '../components/FeaturesSection'
import Layout from '../components/layout'
import React from 'react'
import SEO from '../components/seo'
import StatsSection from '../components/StatsSection'
import TrustedBySection from '../components/TrustedBySection'
import tw from 'twin.macro'
import { Helmet } from 'react-helmet'
import SideSocialMedia from '../components/SideSocialMedia'

const LinkNameGreen = tw.a`inline-flex items-center 
justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 bg-green-500 hover:bg-green-700 focus:shadow-outline focus:outline-none mx-auto`

function IndexPage({ data }) {
  return (
    <Layout bgColor={constants.bgColor} isDark={constants.isDark}>
      <SideSocialMedia />
      <Helmet>
        <meta
          name="google-site-verification"
          content="eNiRNPRwmdps34n_Q7baaLw--zo4ZAn-WmuY1SXLDiM"
        />
      </Helmet>
      <SEO
        keywords={[
          `about us`,
          `about`,
          `spice`,
          `spice supplier`,
          `sinaran rempah`,
          `spices`,
          `spices malaysia`,
        ]}
        title="Home"
      />
      <CustomBgHero />
      <TrustedBySection />
      <FeaturesSection />
      <StatsSection />
      <AboutUsSection />
      {/* <BestSellerSection /> */}
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 mb-20">
        <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
          <div className="flex flex-col justify-center">
            <div className="max-w-xl mb-6">
              <p className="text-lg text-green-500 font-sans font-semibold">
                You can trust us.
              </p>
              <h2 className="max-w-lg mb-6 font-sans text-2xl sm:text-3xl md:text-4xl font-bold tracking-tight text-gray-900 sm:leading-none">
                Our Promises
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                We have committed to producing the best-quality halal food
                products in Malaysia. We are proudly certified by MeSTI and
                HALAL by JAKIM Malaysia for producing quality products that are
                rendered as halal according to the Malaysian halal standards.
                {/*
                We have committed to producing the best-quality food products in
                Malaysia.*/}
                <br />
                <br />
                We have set the set of rules and norms for every person involved
                in producing our herbs and spices, from collecting sources to
                the operating procedures and the staff members. They all adhere
                to the requirements needed to ensure that only the best quality
                food products reach your kitchen.
              </p>
            </div>
            <div className="grid gap-8 row-gap-8 sm:grid-cols-2">
              <div>
                <div className="flex items-center justify-center w-auto h-auto mb-4">
                  <Img fixed={data.halalLogo.childImageSharp.fixed} />
                  {/* <svg
                    className="w-10 h-10 text-deep-purple-accent-400"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg> */}
                </div>
              </div>
              <div>
                <div className="flex items-center justify-center w-auto h-auto mb-4">
                  <Img fixed={data.mestiLogo.childImageSharp.fixed} />

                  {/* <svg
                    className="w-10 h-10 text-deep-purple-accent-400"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg> */}
                </div>
              </div>
            </div>
          </div>
          <div>
            <Img
              fluid={data.productsImage.childImageSharp.fluid}
              className="object-cover w-full h-56 rounded sm:h-96"
            />
          </div>
        </div>
      </div>
      <CustomBgHero2
        backgroundImageUrl={data.desktop2.childImageSharp.fluid}
        customClassName={'min-h-1/2-screen bg-center'}
        overlayClassName={'thirdQuarterOpacity'}
        textContainerWidth={'fullWidth'}
        headerText={
          <h2
            className={`relative z-20 text-3xl sm:text-4xl md:text-5xl font-extrabold text-white text-center`}
          >
            Interested in our products?
            <br /> Contact <span className={'text-green-500'}>us now</span>
          </h2>
        }
        button={
          <div className={'w-auto mt-8 flex items-center justify-center'}>
            <LinkNameGreen as={Link} to={`/contact`}>
              Contact Us
            </LinkNameGreen>
          </div>
        }
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    file(relativePath: { eq: "New_Project-removebg-preview.png" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    desktop2: file(
      relativePath: { eq: "photo-1575319026763-726d092c26c0.jpeg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    productsImage: file(relativePath: { eq: "products-image-sinaran.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    halalLogo: file(relativePath: { eq: "halal-logo.png" }) {
      childImageSharp {
        fixed(quality: 90, width: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mestiLogo: file(relativePath: { eq: "mesti-logo.png" }) {
      childImageSharp {
        fixed(quality: 90, width: 130) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
