import React, { useRef } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Slide } from 'react-slideshow-image'
import useWindowDimensions from '../helpers/useWindowDimensions'
import * as constants from '../consts/index'
import 'react-slideshow-image/dist/styles.css'

const TrustedBySection = () => {
  const itemsToShow = useRef(1)

  const { width } = useWindowDimensions() || {}

  if (width >= constants.lgWidth) {
    itemsToShow.current = 5
  } else if (width >= constants.mdWidth) {
    itemsToShow.current = 3
  } else {
    itemsToShow.current = 1
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          thestorelogo: file(relativePath: { eq: "the-store-logo.png" }) {
            childImageSharp {
              fixed(height: 160, quality: 90) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          murahjimatlogo: file(relativePath: { eq: "murah-jimat-logo-2.png" }) {
            childImageSharp {
              fixed(width: 200, quality: 90) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          ecoshoplogo: file(relativePath: { eq: "eco-shop-logo.png" }) {
            childImageSharp {
              fixed(width: 200, quality: 90) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          familystorelogo: file(relativePath: { eq: "family-store-logo.png" }) {
            childImageSharp {
              fixed(width: 200, quality: 90) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          zonjimat: file(relativePath: { eq: "zon-jimat.png" }) {
            childImageSharp {
              fixed(width: 200, quality: 90) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          mrdollar: file(relativePath: { eq: "mr-dollar.png" }) {
            childImageSharp {
              fixed(width: 200, quality: 90) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={(data) => {
        let images = [
          'thestorelogo',
          'murahjimatlogo',
          'ecoshoplogo',
          'familystorelogo',
          'mrdollar',
          'zonjimat',
        ]

        return (
          <section className="bg-white">
            <div className="relative container px-8 py-8 my-12 mx-auto sm:px-12 lg:px-20 bg-gray-50 rounded-lg border border-gray-200">
              <p className="text-lg text-green-500 font-sans text-center font-semibold">
                Partners
              </p>
              <h1 className="text-3xl sm:text-4xl font-sans font-bold tracking-tight text-center text-gray-900 mb-7 sm:leading-none">
                Our Partners
              </h1>
              <div>
                <Slide slidesToShow={itemsToShow.current} slidesToScroll={1}>
                  {images.map((slide, index) => {
                    return (
                      <div
                        className="each-slide flex items-center justify-center h-full"
                        key={index}
                      >
                        <Img
                          fixed={data[slide].childImageSharp.fixed}
                          className="object-contain w-full h-56 sm:h-96"
                          imgStyle={{
                            objectFit: 'contain',
                          }}
                        ></Img>
                      </div>
                    )
                  })}
                </Slide>
                {/*
                <div className="flex items-center justify-center col-span-12 sm:col-span-6 lg:col-span-3">
                  <Img
                    fixed={data.murahjimatlogo.childImageSharp.fixed}
                    className="object-cover w-full h-56 sm:h-96"
                  >
                  </Img>
                </div>
                <div className="flex items-center justify-center col-span-12 sm:col-span-6 lg:col-span-3">
                  <Img
                    fixed={data.ecoshoplogo.childImageSharp.fixed}
                    className="object-cover w-full h-56 sm:h-96"
                  >
                  </Img>
                </div>
                <div className="flex items-center justify-center col-span-12 sm:col-span-6 lg:col-span-3">
                  <Img
                    fixed={data.familystorelogo.childImageSharp.fixed}
                    className="object-cover w-full h-56 sm:h-96"
                  >
                  </Img>
                </div>
                <div className="flex items-center justify-center col-span-12 sm:col-span-6 lg:col-span-3">
                  <Img
                    fixed={data.mrdollar.childImageSharp.fixed}
                    className="object-cover w-full h-56 sm:h-96"
                  >
                  </Img>
                </div>
                <div className="flex items-center justify-center col-span-12 sm:col-span-6 lg:col-span-3">
                  <Img
                    fixed={data.zonjimat.childImageSharp.fixed}
                    className="object-cover w-full h-56 sm:h-96"
                  >
                  </Img>
                </div>
            */}
              </div>
            </div>
          </section>
        )
      }}
    />
  )
}

export default TrustedBySection
