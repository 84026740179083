/* eslint-disable */
import tw, { styled } from 'twin.macro'
import { Link, graphql, StaticQuery } from 'gatsby'
import { Slide } from 'react-slideshow-image'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import React from 'react'
import { ReactComponent as SvgDecoratorBlob1 } from '../images/random.svg'
import 'react-slideshow-image/dist/styles.css'

const styleMap = {
  halfOpacity: tw`bg-opacity-50`,
  thirdQuarterOpacity: tw`bg-opacity-0`,
  fullOpacity: tw`bg-opacity-20`,
  transparent: tw`bg-opacity-0`,
  halfWidth: tw`w-full lg:w-1/2 lg:pr-12`,
  fullWidth: tw`w-full`,
  'min-h-3/4-screen': tw`min-h-3/4-screen`,
  'min-h-1/2-screen bg-center': tw`min-h-1/2-screen bg-center`,
}

const getStyleName = ({ name }) => styleMap[name]

const Container = tw.div`-mt-10 relative`
const BlackOverlay = styled.div(() => [
  tw`absolute inset-0 w-full h-full bg-black`,
  getStyleName,
])

const DecoratorBlob1 = tw(SvgDecoratorBlob1)`hidden lg:block absolute top-0`

const TwoColumn = styled.div(() => [
  tw`flex flex-col max-w-screen-xl mx-auto md:justify-center lg:flex-row md:items-center p-6 lg:p-0`,
  getStyleName,
])

const LeftColumn = styled.div(() => [
  tw`w-full lg:w-1/2 px-4 mb-12 md:mb-20 lg:mb-0 flex items-center`,
  getStyleName,
])
const RightColumn = tw.div`relative mt-12 lg:mt-0`
const ParagraphText = tw.p`mb-6 text-lg text-white leading-loose`
const ImageContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none z-10`

const CustomBgHero = ({
  backgroundImageUrl,
  imageUrl,
  customClassName,
  overlayClassName,
  paragraphText,
  textContainerWidth,
  headerText,
  button,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "wKseqZWg.png" }) {
            childImageSharp {
              fixed(width: 200, quality: 90) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          desktop2: file(relativePath: { eq: "bg2.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desktop4: file(relativePath: { eq: "bg4.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desktop5: file(relativePath: { eq: "bg5.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const images = [
          data.desktop2.childImageSharp.fluid,
          data.desktop4.childImageSharp.fluid,
          data.desktop5.childImageSharp.fluid,
        ]
        let content = (
          <>
            <div className="absolute inset-0 w-full h-full bg-black bg-opacity-10" />
            <div className="relative w-full pb-20">
              <div className="px-4 py-16 mx-auto w-full sm:max-w-xl md:max-w-full lg:max-w-screen-xl lg:py-20">
                <div className="grid gap-10 lg:grid-cols-2">
                  <div className="lg:pr-10">
                    <h5 className="mb-4 text-4xl lg:text-5xl font-bold font-heading text-white">
                      Winning heart of millions
                      <br className="block" />
                      <span className="inline-block text-green-500">
                        {' '}
                        with our spices
                      </span>
                    </h5>
                    <p className="mb-6 text-lg text-white leading-loose">
                      300+ spices that makes your taste bud say thanks.
                    </p>
                    <hr className="mb-5 border-gray-300" />
                    <div className="flex items-center space-x-4">
                      <div>
                        <Link
                          to={'/contact'}
                          className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 bg-green-500 hover:bg-green-700 focus:shadow-outline focus:outline-none"
                        >
                          Get in Touch
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </>
        )

        return (
          <>
            <Slide slidesToShow={1} indicators={true} arrows={false}>
              {images.map((slide, index) => {
                return (
                  <div className="each-slide w-full h-full" key={index}>
                    <BackgroundImage
                      Tag="section"
                      fluid={slide}
                      style={{ backgroundPosition: 'top' }}
                      key={index}
                    >
                      {content}
                    </BackgroundImage>
                  </div>
                )
              })}
            </Slide>
          </>
        )
      }}
    />
  )
}

export default CustomBgHero
